/** @jsx jsx */
import { Icon } from '@cloudflare/component-icon'
import { Fragment } from 'react'
import { Divider, Flex, jsx, Link } from 'theme-ui'

const Social = ({ type, href }) => {
  return (
    <li
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Link
        variant='plain'
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: [2, 2, 0]
        }}
        href={href}
        rel='noopener noreferrer'
        target='_blank'
      >
        <Icon type={type.toLowerCase()} mr='2' size={24} />
        {type}
      </Link>
    </li>
  )
}

const FooterSocial = () => {
  return (
    <Fragment>
      <Divider />
      <Flex
        as='ul'
        sx={{
          flexDirection: ['column', 'column', 'row'],
          justifyContent: ['center', 'center', 'space-between'],
          flexWrap: 'wrap',
          listStyleType: 'none',
          py: 0,
          px: 4
        }}
      >
        <Social
          type='LinkedIn'
          href='https://linkedin.com/company/cloudflare'
        />
        <Social type='Facebook' href='https://facebook.com/cloudflare' />
        <Social type='Twitter' href='https://twitter.com/cloudflare' />
        <Social type='GitHub' href='https://github.com/cloudflare' />
        <Social type='YouTube' href='https://youtube.com/cloudflare' />
      </Flex>
      <Divider />
    </Fragment>
  )
}

export default FooterSocial
