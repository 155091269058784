/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'

const HeaderDepartment = ({ to, name }) => {
  return (
    <Link
      sx={{
        textDecoration: 'none',
        ml: 2,
        position: 'relative',
        variant: 'links.navigation'
      }}
      to={to}
      children={name}
      activeClassName='active'
      partiallyActive={true}
    />
  )
}

export default HeaderDepartment
