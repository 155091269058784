/** @jsx jsx */
import { Icon } from '@cloudflare/component-icon'
import { useEffect, useRef, useState } from 'react'
import { Flex, jsx } from 'theme-ui'
import HeaderDepartment from './Header/Department'
import HeaderLogo from './Header/Logo'
import { Link } from 'gatsby'
import ProductLockup from '../components/mdx/ProductLockup'

const OBSERVER_OPTIONS = {
  root: null,
  rootMargin: '0px'
}

const Header = ({ sentinelRef }) => {
  const navRef = useRef(null)
  const observer = useRef(null)
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    observer.current = new window.IntersectionObserver(entries => {
      const { isIntersecting } = entries[0]
      setIsSticky(!isIntersecting)
    }, OBSERVER_OPTIONS)

    observer.current.observe(sentinelRef.current)

    return () => observer.current.disconnect()
  }, [sentinelRef])

  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  return (
    <header
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        justifySelf: 'flex-start',
        px: 4,
        pt: [2, 2, 3],
        pb: [1, 1, 2],
        backgroundColor: 'background',
        position: 'sticky',
        top: '0',
        borderBottom: '1px solid',
        // borderColor: `rgba(242,242,242, ${isSticky ? 1 : 0})`,
        // borderColor: 'borderBottomColorVariable' + `${isSticky ? '00' : ''}`,
        borderColor: `${isSticky ? 'decorative' : 'decorativeAlphaZero'}`,
        transitionProperty: 'border-color',
        transitionDuration: '0.45s',
        // boxShadow: `${isSticky ? '' : '0 1px 8px rgba(0,0,0,0.2)'}`,
        zIndex: 98
      }}
      ref={navRef}
    >
      {/* <HeaderLogo /> */}
      <Link
      sx={{
        textDecoration: 'none'
      }}
      to='/'
    >
      <ProductLockup product="Style"/>
    </Link>

      <Flex
        as='nav'
        sx={{
          flexDirection: ['column', 'column', 'row'],
          justifyContent: ['center', 'center', 'flex-start'],
          position: ['absolute', 'absolute', 'relative'],
          top: ['0', '0', 'auto'],
          left: ['0', '0', 'auto'],
          width: ['100vw', '100vw', 'auto'],
          height: ['100vh', '100vh', 'auto'],
          backgroundColor: ['background', 'background', 'none'],
          fontSize: [6, 6, 3],
          textAlign: ['center', 'center', 'initial'],
          display: [
            hamburgerOpen ? null : 'none',
            hamburgerOpen ? null : 'none',
            'block'
          ],
          opacity: [hamburgerOpen ? 1 : 0, hamburgerOpen ? 1 : 0, 1],
          transitionProperty: 'opacity',
          transitionDuration: '0.45s'
          // Animation of moving in doesn't work yet because https://github.com/system-ui/theme-ui/issues/1814
        }}
      >
        <HeaderDepartment to='/brand' name='Brand' />
        <HeaderDepartment to='/content' name='Content' />
        <HeaderDepartment to='/primitives' name='Primitives' />
        <HeaderDepartment to='/components' name='Components' />
        <HeaderDepartment to='/guides' name='Guides' />
        <HeaderDepartment to='/resources' name='Resources' />
      </Flex>

      <div
        onClick={() => setHamburgerOpen(!hamburgerOpen)}
        sx={{ display: ['block', 'block', 'none'], zIndex: 99 }}
        role='button'
      >
        <Icon type={hamburgerOpen ? 'remove' : 'hamburger'} size={24} />
      </div>
    </header>
  )
}

export default Header
