/** @jsx jsx */
import { useEffect } from 'react'
import { Logo } from '@cloudflare/component-logo'
import { Button, Flex, jsx, Link as ThemedLink, useColorMode } from 'theme-ui'

const Link = ({ href, name }) => {
  return (
    <li sx={{ ml: 2, pl: 2 }}>
      <ThemedLink
        variant='plain'
        href={href}
        rel='noopener noreferrer'
        target='_blank'
      >
        {name}
      </ThemedLink>
    </li>
  )
}

const FooterLegal = ({ withoutCookie = false, withoutPrivacy = false }) => {
  const [colorMode, setColorMode] = useColorMode()

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.keyCode === 68 && e.shiftKey) {
        setColorMode(currentColorMode =>
          currentColorMode === 'default' ? 'dark' : 'default'
        )
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Flex
      sx={{
        // flexDirection: ['column', 'column', 'row'],
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'row'],
          columnGap: '24px'
        }}
      >
        <Logo light={colorMode === 'dark'} />

        <ul
          sx={{
            display: 'flex',
            flexDirection: ['column', 'column', 'row'],
            alignItems: ['flex-start', 'flex-start', 'unset'],
            textAlign: ['center', 'center', 'initial'],
            fontSize: 2,
            listStyleType: 'none',
            p: 0,
            li: { display: 'inline-block', mb: 2 }
          }}
        >
          <li>© 2021 Cloudflare, Inc.</li>
          <Link
            name='About Cloudflare'
            href='https://cloudflare.com/about-overview/'
          />
          {withoutCookie ? null : <Link name='Cookie Preferences' href='#' />}
          <Link name='Careers' href='https://cloudflare.com/careers/' />
          {withoutPrivacy ? null : (
            <Link
              name='Privacy Policy'
              href='https://cloudflare.com/privacypolicy/'
            />
          )}
          <Link
            name='Terms of Use'
            href='https://cloudflare.com/website-terms/'
          />
          <Link name='Disclosure' href='https://cloudflare.com/disclosure/' />
          <Link name='Trademark' href='https://cloudflare.com/trademark/' />
        </ul>
      </Flex>

      <Flex
        sx={{
          flexDirection: ['column', 'column', 'row'],
          justifyContent: ['flex-start', 'flex-start', 'center'],
          alignItems: ['flex-start', 'flex-start', 'center'],
          columnGap: '24px'
        }}
      >
        <Button
          onClick={e => {
            setColorMode(colorMode === 'default' ? 'dark' : 'default')
          }}
          variant='ghost'
        >
          <span
            sx={{
              alignSelf: 'center',
              fontSize: 2
            }}
          >
            {colorMode === 'default' ? 'Use Dark theme' : 'Use Light theme'}
          </span>
        </Button>
      </Flex>
    </Flex>
  )
}

export default FooterLegal
