/** @jsx jsx */
import { jsx } from 'theme-ui'
import FooterLegal from './Footer/Legal'
import FooterOverview from './Footer/Overview'
import FooterSocial from './Footer/Social'

const Footer = ({ type }) => {
  return (
    <footer sx={{ '& > *': { px: 4 }, pt: 2, pb: 3 }}>
      {type === 'overview' ? <FooterOverview /> : null}
      <FooterSocial />
      <FooterLegal withoutCookie withoutPrivacy />
    </footer>
  )
}

export default Footer
