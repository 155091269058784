/** @jsx jsx */
import { Global } from '@emotion/react'
import React, { useRef } from 'react'
import { Flex, jsx } from 'theme-ui'
import Footer from './Footer'
import Header from './Header'
import { Toast } from '@cloudflare/component-toast'
import SEO from './SEO'

const Layout = ({
  children,
  footerType,
  isHome,
  title,
  description,
  sidebarOpen
}) => {
  const sentinelRef = useRef(null)

  return (
    <React.Fragment>
      <SEO title={title} description={description} />
      <Global
        styles={() => ({
          a: {
            color: 'inherit'
          }
        })}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          maxWidth: 1440,
          minHeight: '100vh',
          mx: 'auto',
          maxHeight: [
            sidebarOpen ? '100vh' : null,
            sidebarOpen ? '100vh' : null,
            null
          ],
          overflow: [
            sidebarOpen ? 'hidden' : null,
            sidebarOpen ? 'hidden' : null,
            null
          ]
        }}
      >
        <div ref={sentinelRef} />
        <Header sentinelRef={sentinelRef} />
        <main
          sx={{
            flexGrow: 1,
            pt: isHome ? 2 : 2,
            mb: [0, 0, 6],
            px: 4,
            // ':after': {
            //   boxShadow: '0px 0px 30px 50px rgba(255,0,0,0.5)',
            //   content: '" "',
            //   height: '100%',
            //   left: 0,
            //   position: 'absolute',
            //   top: '-200px',
            //   width: '100%'
            // }
            // ':after': {
            //   boxShadow: '1px 0px 30px 50px rgba(0,255,0,0.5)',
            //   content: '" "',
            //   height: '100%',
            //   right: '-8px',
            //   position: 'absolute',
            //   top: 0,
            //   width: '20px',
            //   zIndex: '-99'
            // }
            // boxShadow: ['', '', '0px -80px 30px 50px rgba(0,0,0,0.02)']
            // boxShadow: ['', '', '0px -80px 24px 8px rgba(0,0,0,0.2)']
            boxShadow: ['', '', '0px -40px 32px -16px rgba(0,0,0,0.2)']
          }}
        >
          <Toast type='info'>
            Cloudflare Style is in Alpha and is a work-in-progress. Expect
            changes. Contact DESSYS with any questions.
          </Toast>
          {children}
          {/* <div
            sx={{
              boxShadow: '0px 0px 30px 50px rgba(0,0,0,0.05)',
              content: '" "',
              height: '100%',
              left: 0,
              position: 'absolute',
              top: '-200px',
              width: '100%'
            }}
          ></div> */}
        </main>
        <Footer type={footerType} />
      </Flex>
    </React.Fragment>
  )
}

export default Layout
