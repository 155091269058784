/** @jsx jsx */
import { Icon } from '@cloudflare/component-icon'
import React, { useState } from 'react'
import { Divider, Grid, jsx, Link } from 'theme-ui'

const Column = ({ heading, elements }) => {
  const [expanded, setExpanded] = useState(false)

  const contentDisplay = content => {
    if (typeof content === 'string') {
      return content
    } else if (Array.isArray(content)) {
      return content.map((c, i) => (
        <React.Fragment key={i}>
          {c}
          <br />
        </React.Fragment>
      ))
    }
  }

  return (
    <ul sx={{ listStyle: 'none', m: 0, p: 0, my: [1, 1, 0] }}>
      <li
        sx={{
          fontWeight: 'heading',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        onClick={() => setExpanded(!expanded)}
      >
        {heading}
        <Icon
          type='caret-down'
          sx={{
            display: ['block', 'block', 'none'],
            transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)',
            transitionDuration: '0.2s'
          }}
        />
      </li>
      {elements.map((element, i) => (
        <li
          key={i}
          sx={{
            display: [
              expanded ? 'block' : 'none',
              expanded ? 'block' : 'none',
              'block'
            ],
            mt: 3
          }}
        >
          {element.href ? (
            <Link
              variant='plain'
              href={element.href}
              rel='noopener noreferrer'
              target='_blank'
            >
              {contentDisplay(element.content)}
            </Link>
          ) : (
            contentDisplay(element.content)
          )}
        </li>
      ))}
    </ul>
  )
}

const FooterOverview = () => {
  return (
    <>
      <Divider />
      <Grid columns={[1, 3, 6]}>
        <Column
          heading='Sales'
          elements={[
            { content: ['Americas', '+1 (888) 99 FLARE'] },
            { content: ['Europe', '+44 20 3514 6970'] },
            { content: ['Asia Pacific', '+65 3158 3954'] }
          ]}
        />
        <Column
          heading='Customer Support'
          elements={[
            {
              content: 'System Status',
              href: 'https://cloudflarestatus.com/'
            },
            {
              content: 'Help Center',
              href: 'https://support.cloudflare.com/'
            },
            {
              content: 'Community Forum',
              href: 'https://community.cloudflare.com/'
            },
            {
              content: 'Developer Documentation',
              href: 'https://developers.cloudflare.com/'
            },
            {
              content: 'API Documentation',
              href: 'https://api.cloudflare.com/'
            }
          ]}
        />
        <Column
          heading='Getting Started'
          elements={[
            {
              content: 'Industry Analytics',
              href: 'https://cloudflare.com/analysts/'
            },
            { content: 'Pricing', href: 'https://cloudflare.com/plans/' },
            {
              content: 'Case Studies',
              href: 'https://cloudflare.com/case-studies/'
            },
            {
              content: 'Resources Hub',
              href: 'https://cloudflare.com/resources/'
            },
            {
              content: 'Webinars',
              href: 'https://cloudflare.com/resource-hub?resourcetype=Webinar'
            },
            {
              content: 'Learning Center',
              href: 'https://cloudflare.com/learning/'
            }
          ]}
        />
        <Column
          heading='Public Interest'
          elements={[
            {
              content: 'Athenian Project',
              href: 'https://cloudflare.com/athenian/'
            },
            {
              content: 'Project Galileo',
              href: 'https://cloudflare.com/galileo/'
            },
            {
              content: 'Project Fair Shot',
              href: 'https://cloudflare.com/fair-shot/'
            },
            {
              content: 'Cloudflare for Campaigns',
              href: 'https://cloudflare.com/campaigns/'
            },
            {
              content: 'Cloudflare Radar',
              href: 'https://radar.cloudflare.com/'
            }
          ]}
        />
        <Column
          heading='Content'
          elements={[
            {
              content: 'Cloudflare Blog',
              href: 'https://blog.cloudflare.com/'
            },
            { content: 'Cloudflare TV', href: 'https://cloudflare.tv/' }
          ]}
        />
        <Column
          heading='Company'
          elements={[
            {
              content: 'About Cloudflare',
              href: 'https://cloudflare.com/about-overview/'
            },
            { content: 'Investor Relations', href: 'https://cloudflare.net/' },
            { content: 'Our Team', href: 'https://cloudflare.com/people/' },
            { content: 'Press', href: 'https://cloudflare.com/press/' },
            {
              content: "Careers - We're hiring!",
              href: 'https://cloudflare.com/careers/'
            },
            {
              content: 'Brand Assets',
              href: 'https://cloudflare.com/logo/'
            }
          ]}
        />
      </Grid>
    </>
  )
}

export default FooterOverview
