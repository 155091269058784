import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import favicon from "../images/favicon.ico";

const SEO = ({ lang = "en-US", title, description }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
          }
        }
      }
    `
  );

  const { pathname } = useLocation();

  const isHomepage = pathname === "/";

  title = title ? `${title} · ${siteMetadata.title}` : siteMetadata.title;

  description = description || siteMetadata.description;

  return (
    <Helmet>
      <html lang={lang} />

      <title>{title}</title>

      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={isHomepage ? "website" : "article"} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="og:url" content={`${siteMetadata.url}${pathname}`} />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"
        rel="stylesheet"
      />

      <link rel="icon" href={favicon} />
    </Helmet>
  );
};

export default SEO;
